import { Injectable } from '@angular/core';
import { CommonHttpService } from 'src/app/core/interceptor/header-interceptor';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private commonHttpService: CommonHttpService) { }

  onLogin(formValue) {
    return this.commonHttpService.post('admin/login', formValue);
  }


 onForgot(formValue) {
    return this.commonHttpService.post('admin/forgot.password', formValue);
  }
} 
